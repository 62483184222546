import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '../views/Layout.vue'
import Goods from '../views/goods'
import Home from '../views/home'
import category from '../views/category'
import Search from '../views/search'


const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      { path: '/', component: Home },
      { path: '/product/:id', component: Goods },
      { path: '/product/category/:id', component: category },
      { path: '/search', component: Search },
    ]
    }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
