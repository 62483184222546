export default {
    namespaced: true,
    state() {
        return {
            list: []
        }
    },

    getters: {
        gflist (state){
            return state.list.filter(item => item.parent_id2===20)
        },
        peplist (state){
            return state.list.filter(item => item.parent_id2===21)
        },
        otherbook (state){
            return state.list.filter(item => item.parent_id2===22)
        },
        huibenbook (state){
            return state.list.filter(item => item.parent_id2===23)
        }
    

    },

    mutations: {
      
    },


    actions: {
      
    }

}