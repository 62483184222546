export default {
  namespaced: true,
  state() {
    return {

      list: [
        {series:'语法类'},
        {series:'人教版'},
        {series:'外研版'},
        {series:'新概念'},
        {series:'苏教译林'},
        {series:'绘本阅读'},
        {series:'音标课件'},
        {series:'小游戏课堂互动课件'},

      ],
      listtwo: [
        [
        {seriestwo:'小学'},
        {seriestwo:'初中'},
        {seriestwo:'高中'},
        ],
        [
          {seriestwo:'小学'},
          {seriestwo:'初中'},
          {seriestwo:'高中'},
        ],
        [
          {seriestwo:'小学'},
          {seriestwo:'初中'},
          {seriestwo:'高中'},
        ],
        [
          {seriestwo:'成人版'},
          {seriestwo:'青少版'},
        ],
        [
          {seriestwo:'小学'},
        ],
        [
          {seriestwo:'黑布林'},
          {seriestwo:'丽声北极星'},
          {seriestwo:'RAZ'},
          {seriestwo:'牛津阅读树'},
        ],
        [
          {seriestwo:'启蒙音标课件'},
          {seriestwo:'进阶音标课件'},
        ],
        [
          {seriestwo:'单词模板'}
        ],
         
      ]
    }
  },
  // getters: {
  //   selectlisttwo(state) {
  //     return state.list.filter(item => { return item.id == '' })
  //   }
  // }
}
