<template>
  <div class="container" v-if="list[0].list_id">
    <div class="row mb-3">
      <h3
        class="text-right offset-lg-4"
        v-for="(item, i) in $store.getters['product/showlist']"
        :key="i"
      >
        {{ item.subtitle }}
      </h3>
    </div>

    <div class="row">
      <div class="col-lg-3 col-sm-12 mb-3 videolist">
        <img
          id="jiantou"
          class="picutrecontroller"
          src="@/assets/images/jiantou.png"
          alt=""
          v-show="isShow"
        />
        <br v-show="isShow" />
        <h1 class="H1"
          style="
            margin-left: 3em;
            font-size: 28px;
            font-family: STHupo;
            color: #ff9999;
          "
        >
          <span>点列表选择不同单元</span>
          <!-- <span>列</span>
          <span>表</span>
          <span>选</span>
          <span>择</span>
          <span>单元</span>
          <span></span> -->
        </h1>
        <select
          class="form-select form-select-lg"
          aria-label="Default select example"
          v-model="selected"
          @click="chooseCore"
        >
          <option selected>视频列表</option>
          <option :value="item.list_id" v-for="(item, i) in list" :key="i">
            {{ item.subtitle }}
          </option>
        </select>
      </div>

      <div
        class="col-lg-8 col-sm-12 text-center py-3 px-auto border rounded offset-lg-1 mb-3"
        v-for="item in $store.getters['product/showlist']"
        :key="item.video_url2"
      >
        <div class="ratio ratio-16x9">
          <video
            controls
            controlslist="nodownload "
            oncontextmenu="return false;"
            class="embed-responsive-item"
            :poster="`${item.poster ? item.poster : item.picture_url}`"
          >
            <source :src="item.video_url2" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </div>

  <div class="container" v-else>
    <div class="row mb-3">
      <h3 class="text-left offset-lg-1" v-for="(item, i) in list" :key="i">
        {{ item.title }}
      </h3>
    </div>

    <div class="col-lg-3 col-sm-12 mb-3" v-show="list[0].list_id">
      <select
        class="form-select"
        aria-label="Default select example"
        v-model="selected"
      >
        <option selected>视频列表</option>
        <option :value="item.list_id" v-for="(item, i) in list" :key="i">
          {{ item.subtitle }}
        </option>
      </select>
    </div>

    <div class="row">
      <div
        class="col-lg-8 col-sm-12 text-center py-3 px-auto border rounded offset-lg-1 mb-3"
        v-for="item in list"
        :key="item.video_url1"
      >
        <div class="ratio ratio-16x9">
          <video
            controls
            controlslist="nodownload "
            oncontextmenu="return false;"
            class="embed-responsive-item"
            :poster="`${item.poster ? item.poster : item.picture_url}`"
          >
            <source :src="item.video_url1" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { findTheProduct } from "@/api/product";
export default {
  name: "Goods",
  setup(props, context) {
    onMounted(() => {});

    const route = useRoute();
    const store = useStore();
    const list = computed(() => {
      return store.state.product.list;
    });

    const chooseCore = () => {
      isShow.value = false;
    };

    const isShow = ref(true);

    watch(
      () => route.params.id,
      (newVal) => {
        if (newVal && `/product/${newVal}` === route.path)
          findTheProduct(route.params.id).then((res) => {
            nextTick(() => {
              return (store.state.product.list = ref(res.data.list));
            });
          });
      },
      { immediate: true }
    );

    const selected = ref("视频列表");

    watch(selected, (newX) => {
      if (newX === "视频列表") {
        return;
      }
      store.commit("product/chooseVideo", newX), { immediate: true };
    });

    return { list, selected, chooseCore, isShow };
  },
};
</script>


<style>
@import "@/assets/font/font.css";
.h1{
  cursor: default;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  margin: auto;
  display: block;
  text-align: center;
}
h1 span {
  position: relative;
  top: 1px;
  display: inline-block;
  
  color: #FF6666;
  /* text-shadow: 0 1px 0 #ff9999, 0 2px 0 #ff9999, 0 3px 0 #ff9999, 0 4px 0 #ff9999, 0 5px 0 #ff9999, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent, 0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4); */
  animation: bounce 0.3s ease infinite alternate;
}
@keyframes bounce {
  100% {
    top: -20px;
    /* text-shadow: 0 1px 0 #ff9999, 0 2px 0 #ff9999, 0 3px 0 #ff9999, 0 4px 0 #ff9999, 0 5px 0 #ff9999, 0 6px 0 #ff9999, 0 7px 0 #ff9999, 0 8px 0 #ff9999, 0 9px 0 #ff9999, 0 50px 25px rgba(0, 0, 0, 0.2); */
  }
}
/* h1 span:nth-child(2) {
  animation-delay: 0.1s;
}
h1 span:nth-child(3) {
  animation-delay: 0.2s;
}
h1 span:nth-child(4) {
  animation-delay: 0.3s;
}
h1 span:nth-child(5) {
  animation-delay: 0.4s;
}
h1 span:nth-child(6) {
  animation-delay: 0.5s;
}
h1 span:nth-child(7) {
  animation-delay: 0.6s;
}
h1 span:nth-child(8) {
  animation-delay: 0.7s;
}
h1 span:nth-child(9) {
  animation-delay: 0.8s;
} */

.card:hover {
  box-shadow: 3px 3px 10px 0 #4c504f, -3px -3px 10px 0 #4c504f;
}
#jiantou {
  margin: 0 auto;
  height: 50px;
  text-align: center;
}
.videolist {
  position: relative;
}

.picutrecontroller {
  position: absolute;
  animation: example 1s infinite linear;
  left: 2em;
}
@keyframes example {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>