import { findAllProduct } from '@/api/product'

export default {
    namespaced: true,
    state() {
        return {
            list: [],
            categorylist: [],

        }
    },
    getters: {
        showlist(state) {
            return state.list.filter(item => { return item.selected > 0 })
        }

    },

    mutations: {

        chooseVideo(state, payload) {
            state.list.forEach(
                item => {
                    item.selected = 0
                    if (item.list_id === payload) {
                        item.selected = 1
                    }else return
                }
            )

        }

    },
}



