import request from '@/utils/request'



/**
 * 
 * @param {String} category_id -
 * @returns promise
 */

  //查产品的详细信息
export const findTheProduct = (product_id) => {
    return request('/api/v1/product/find','get',{product_id})
  }

// 首页接口
  export const findhomeProduct = () => {
    return request('/api/v1/home/find2','get')
  }

  //分类接口
  export const findCategoryProduct = (parent_id) => {
    return request('/api/v1/category/find3','get',{parent_id})
  }

