<template>
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-6">
        <div class="card a mb-3">
          <router-link to="/product">
            <img
              src="@/assets/images/ceshi.png"
              class="card-img-top"
              style="width:100px height:100px"
              alt="..."
            />
          </router-link>
          <!-- <button type="button" class="btn btn-light b d-none d-md-block d-lg-block" >详细介绍</button> -->
          <!-- 设置了响应式隐藏按钮d-none d-md-block d-lg-block -->
          <div class="card-body">
            <h6 class="card-title">
              Card titleCard titleCard titleCard titleCard title
            </h6>
          </div>
        </div>
      </div>

      
      
    </div>
  </div>
</template>

<script>
export default {
    name:'Search'




};
</script>

<style>
</style>