<template>
  <div class="container">
    <h2 class="text-left mt-3">中小学语法</h2>
    <div class="row">
      <div
        class="col-md-3 col-sm-6 col-6"
        v-for="item in $store.getters['home/gflist']"
        :key="item.id"
      >
        <div class="card a mb-3">
          <RouterLink :to="`/product/category/${item.parent_id}`">
            <img :src="item.picture_url" class="card-img-top" alt="..." />
          </RouterLink>
          <!-- <button type="button" class="btn btn-light b d-none d-md-block d-lg-block" >详细介绍</button> -->
          <!-- 设置了响应式隐藏按钮d-none d-md-block d-lg-block -->
          <div class="card-body">
            <h6 class="card-title">{{ item.title }}</h6>
          </div>
        </div>
      </div>
    </div>

    <h2 class="text-left mt-3">人教版新目标</h2>
    <div class="row">
      <div
        class="col-md-3 col-sm-6 col-6"
        v-for="item in $store.getters['home/peplist']"
        :key="item.id"
      >
        <div class="card a mb-3">
          <RouterLink :to="`/product/category/${item.parent_id}`">
            <img :src="item.picture_url" class="card-img-top" alt="..." />
          </RouterLink>

          <div class="card-body">
            <h6 class="card-title">
              {{ item.title }}
            </h6>
          </div>
        </div>
      </div>
    </div>

    <h2 class="text-left mt-3">同步教材课件</h2>
    <div class="row">
      <div
        class="col-md-3 col-sm-6 col-6"
        v-for="item in $store.getters['home/otherbook']"
        :key="item.id"
      >
        <div class="card a mb-3">
          <RouterLink :to="`/product/category/${item.parent_id}`">
            <img :src="item.picture_url" class="card-img-top" alt="..." />
          </RouterLink>

          <div class="card-body">
            <h6 class="card-title">
              {{ item.title }}
            </h6>
          </div>
        </div>
      </div>
    </div>

    <h2 class="text-left mt-3">音标&绘本阅读</h2>
    <div class="row">
      <div
        class="col-md-3 col-sm-6 col-6"
        v-for="item in $store.getters['home/huibenbook']"
        :key="item.id"
      >
        <div class="card a mb-3">
          <RouterLink :to="`/product/category/${item.parent_id}`">
            <img :src="item.picture_url" class="card-img-top" alt="..." />
          </RouterLink>

          <div class="card-body">
            <h6 class="card-title">
              {{ item.title }}
            </h6>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
  
  <script>
import { ref, computed, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { findhomeProduct } from "@/api/product";

export default {
  name: "HomePage",

  setup() {
    const store = useStore();
    const route = useRoute();

    const list = computed(() => {
      return store.state.home.list;
    });

    findhomeProduct().then((res) => {
      // console.log(res.data.list)
      return (store.state.home.list = res.data.list);
    });

    return { list };
  },
};
</script>
  
  <style scoped>
.card:hover {
  box-shadow: 3px 3px 10px 0 #4c504f, -3px -3px 10px 0 #4c504f;
}

.a {
  position: relative !important;
}
.b {
  position: absolute !important;
  width: 50%;
  height: 20%;
  top: 35%;
  left: 25%;
}
</style>