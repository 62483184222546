<template>
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-6" v-for="item in list" :key="item.id">

        <div  class="card a mb-3">
          <RouterLink :to="`/product/${item.product_id}`">
            <img :src="item.picture_url" class="card-img-top" alt="..." />
          </RouterLink>

          <!-- <button type="button" class="btn btn-light b d-none d-md-block d-lg-block" >详细介绍</button> -->
          <!-- 设置了响应式隐藏按钮d-none d-md-block d-lg-block -->
          <div class="card-body">
            <h6 class="card-title">{{ item.title }}</h6>
          </div>
        </div>

      </div>
    </div>
    
  </div>
</template>
  
  <script>
import { useStore } from "vuex";
import { onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { findCategoryProduct } from "@/api/product";
export default {
  name: "category",
  setup() {
    onMounted(() => {});
    const store = useStore();
    const route = useRoute();
    const list = computed(() => {
      return store.state.product.categorylist;
    });

    findCategoryProduct(route.params.id).then((res) => {
      console.log(res.data.list);
      return (store.state.product.categorylist = res.data.list);
    });

    return { list };
  },
};
</script>
  
  
  <style>
.card:hover {
  box-shadow: 3px 3px 10px 0 #4c504f, -3px -3px 10px 0 #4c504f;
}
</style>