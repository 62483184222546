import { createStore } from 'vuex'
import selectlist from './modules/selectlist'
import product from './modules/product'
import home from './modules/home'

export default createStore({
  modules: {
    selectlist,
    product,
    home,
  }
})
