<template>
  <body class="container">
    <div class="row">
      <div class="col-3">
        
        <select
          class="form-select  mb-3 "
          aria-label=".form-select-lg example"
          v-model="ssss"
        >
         <option selected>系列</option>
          <option  v-for="(item, index) in selectlist"
          :key="index" :value="index" >
          {{ item.series }}
          </option>
        </select>
      </div>

      <!-- {{ ssss }} -->

      <div class="col-3">
      <select
        class="form-select  mb-3"
        aria-label=".form-select-lg example"
        v-model="abc"
      >
        <option selected>请选择</option>
        <option v-for="item,index in selectlist_two[ssss]"
          :key="index" :value="item.seriestwo">   {{  item.seriestwo }}   </option>
        
      </select>
    
      

     </div>
      <div class="col-3">
        <button type="button" class="btn btn-primary "  @click="123">搜索</button>
     </div>
     <div class="col-3">  

      </div>
    </div>

    <!-- {{ abc }} -->

  </body>
</template>

<script>
import { computed,ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: "OpptClassify",
  setup(){
    const store = useStore()
    const ssss = ref('系列')
    const abc = ref('请选择')

    const selectlist = computed(() => {
      return store.state.selectlist.list
    })
    const selectlist_two = computed(() => {
      return store.state.selectlist.listtwo
    })

    return { selectlist,ssss, abc,selectlist_two}
  }
};
</script> 

<style>
</style>