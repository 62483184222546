<template>
  <div class="container-fluid bg-dark mt-4">
    <footer style="width: 100% ; overflow: hidden">
      <hr  />
      <div class="row mt-3" style="text-align: center">
        <div class="col-sm-4 col-md-4 col-lg-4 ">
          <el-icon color="#409EFC" class="no-inherit">
            <Select
              style="
                width: 2em;
                height: 2em;
                margin-right: 0.5em;
                margin-bottom: 0.5em;
                vertical-align: middle;
                color: #FFFFFF;
              "
            />
          </el-icon>
          <a href="javascript:" style="color: #FFFFFF">精讲精练</a>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4 ">
          <el-icon color="#409EFC" class="no-inherit">
            <Select
              style="
                width: 2em;
                height: 2em;
                margin-right: 0.5em;
                margin-bottom: 0.5em;
                vertical-align: middle;
                color: #FFFFFF;
              "
            />
          </el-icon>
          <a href="javascript:" style="color: #FFFFFF ">趣味课堂</a>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4 ">
          <el-icon color="#409EFC" class="no-inherit">
            <Select
              style="
                width: 2em;
                height: 2em;
                margin-right: 0.5em;
                margin-bottom: 0.5em;
                vertical-align: middle;
                color: #FFFFFF;
              "
            />
          </el-icon>
          <a href="javascript:" style="color: #FFFFFF">专业一线教师</a>
        </div>
        <hr  />
        <hr  />
        <hr style="border: 1px solid #FFFFFF" />
      </div>
    </footer>
    <div class="row mt-2" style="text-align: center">
    <a href="https://beian.miit.gov.cn/" style="color: #FFFFFF; font-size: 0.5rem;" target="_blank">粤ICP备2023020050号-1</a>
    <hr  />
    </div>
  </div>





</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
a {
  text-decoration: none;
  font-size: 1.2rem;
}
</style>