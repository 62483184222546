<template>
  
   
       <nav class="navbar navbar-expand-md bg-body-tertiary">
         <div class="container">
           <router-link class="navbar-brand" to="/">
           <img src="@/assets/images/logo.png" alt="" />
         </router-link>
           <button
             class="navbar-toggler"
             type="button"
             data-bs-toggle="collapse"
             data-bs-target="#navbarSupportedContent"
             aria-controls="navbarSupportedContent"
             aria-expanded="false"
             aria-label="Toggle navigation"
           >
             <span class="navbar-toggler-icon"></span>
           </button>
           <div class="collapse navbar-collapse" id="navbarSupportedContent">
             <ul class="navbar-nav me-auto mb-2 mb-lg-0">
               <li class="nav-item">
                 <router-link class="nav-link bg-light mr-3" to="/"
                 >首页 </router-link>
               </li>
               <li class="nav-item">
                 <router-link class="nav-link bg-light ml-3" to="/fenlei"
                 >全部分类 </router-link>
               </li>
             </ul>
             <form class="d-flex" role="search">
               <input
                 class="form-control me-2"
                 type="search"
                 placeholder="输入搜索内容"
                 aria-label="Search"
               />
               <button class="btn btn-outline-success"  @click="find" type="submit">
                 search
               </button>
             </form>
           </div>
         </div>
       </nav>
       <OpptClassify></OpptClassify>
 
 
   </template>
   
   <script>
  import { ShoppingCart, Search } from "@element-plus/icons-vue";
  import { useRouter } from 'vue-router';
 
  import OpptClassify from '@/components/library/oppt-classify.vue';
 
   export default {
    components: { ShoppingCart, Search,OpptClassify },
       name: 'home',
       setup () {
         const iconName = "Search";


         const router = useRouter()
         const find = () => {
          router.push('/search')
         }

         return{find}

       }
       
 }
   
   </script>
   
 <style lang="less" scoped>
 </style>